<template>
    <div>
        <div>
            <v-snackbar
                :value="alert"
                :timeout="2000"
                :color="alertType"
                class="rounded"
                centered
                left>
                {{alertText}}
            </v-snackbar>
        </div>
    </div>        
</template>
<script>

export default({
    props:['alert','alertText','alertType'],
    //Alert type:{success,warning,error,dark}

})

</script>
<style scoped>
</style>