<template>
  <v-card class="ma-0 pa-0 elevation-0 white" style="max-height:100%;width:100vw;">
    <div class="resetpwd">
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-on='on' v-bind="attrs" icon>
            <v-icon
              @click="resetUserPassword()">
              mdi-lock-reset
            </v-icon>
          </v-btn>
        </template>
        <span>{{$t("resetpassword")}}</span>
      </v-tooltip>
      <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-on='on' v-bind="attrs" icon>
            <v-icon class="error--text"
              @click="logout()">
              mdi-power
            </v-icon>
          </v-btn>
        </template>
        <span>{{$t("logout")}}</span>
      </v-tooltip>
    </div>
   <div class="ma-3 my-border">
     <v-card-title class="py-0 my-0 customlightgray">
       <v-row class="ma-0 pa-0 py-2 justify-center text-left">
            <v-col class="pa-0 ma-auto" cols="6" sm="4" md="4" lg="4" order="1" order-md="1">
              <div class="font-weight-medium text-left text--secondary">  
                  <div class="ma-auto pa-auto">
                    <span class="ma-0 pa-1">{{ $t("tenants") }}</span>
                    <span style=" border-radius: 22px; font-size:0.6em;"
                        class="custom-color-accent pa-1">{{tenants.length}}
                    </span>
                  </div>
              </div>
            </v-col>
            <v-col class="ma-0 pa-0 text-center" order="3" order-md="2"
              cols="12" 
              sm="12"
              md="4"
              lg="4">  
                  <v-text-field
                    v-model="search.searchWord"
                    class="ma-0 pa-0"
                    append-icon="mdi-magnify"
                    :label="$t('search')"
                    dense 
                    outlined
                    clearable
                    hide-details
                  >
                  </v-text-field> 
            </v-col>
            <v-col class="text-right pa-0 my-2 ma-md-auto" cols="6" sm="8" md="4" lg="4" order="2" order-md="3"> 
                <v-btn class="ma-auto pa-auto custom-color-accent" depressed @click="newItem()">{{$t("newtenant")}}</v-btn>
            </v-col>
            
        </v-row>
      </v-card-title>
      <v-dialog v-model="dialog" max-width="600px">
        <v-card class="customoffwhite">
          <v-card-title class="justify-center ma-0 customlightgray">
            <span class="font-weight-light">{{$t(formTitle)}}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="ma-0 pa-0">
            <v-container class="ma-0">
              <v-form  v-on:submit.prevent ref="form"
              v-model="valid"
              lazy-validation >
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field v-model="editedItem.email" prepend-inner-icon="mdi-email" :rules="[rules.required,rules.email]" outlined dense :label="$t('email')" :placeholder="$t('email')"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field v-model="editedItem.first_name" prepend-inner-icon="mdi-card-account-details" :rules="[rules.required,rules.minU]" outlined dense :label="$t('firstname')" :placeholder="$t('firstname')"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field v-model="editedItem.last_name" prepend-inner-icon="mdi-card-account-details-outline" :rules="[rules.required,rules.minU]" outlined dense :label="$t('lastname')" :placeholder="$t('lastname')"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field v-model="editedItem.username" prepend-inner-icon="mdi-card-account-details" :rules="[rules.required,rules.minU]" outlined dense :label="$t('username')" :placeholder="$t('username')"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field v-model="editedItem.password" :rules="[rules.required,rules.min]" 
                                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                :type="show ? 'text' : 'password'"
                                counter prepend-inner-icon="mdi-lock"
                                autocomplete="off"
                                @click:append="show = !show"
                                outlined dense :label="$t('password')" :placeholder="$t('password')"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field v-model="editedItem.telephone" prepend-inner-icon="mdi-phone" :rules="[rules.required,rules.minU]" outlined dense :label="$t('telephone')" :placeholder="$t('telephone')"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field v-model="editedItem.sub_domain" prepend-inner-icon="mdi-desktop-classic" :rules="[rules.required]" outlined dense :label="$t('subdomain')" :placeholder="$t('subdomain')"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field v-model="editedItem.tenancy_db_username" prepend-inner-icon="mdi-database" :rules="[rules.required,rules.minU]" outlined dense :label="$t('dbusername')" :placeholder="$t('dbusername')"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field v-model="editedItem.tenancy_db_password" :rules="[rules.required,rules.min]" 
                                :append-icon="showdb ? 'mdi-eye' : 'mdi-eye-off'"
                                :type="showdb ? 'text' : 'password'"
                                counter prepend-inner-icon="mdi-database-lock"
                                autocomplete="off"
                                @click:append="showdb = !showdb"
                                outlined dense :label="$t('dbpassword')" :placeholder="$t('dbpassword')"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field v-model="editedItem.organization_suffix" prepend-inner-icon="mdi-domain" :rules="[rules.required]" outlined dense :label="$t('OrganizationSuffix')" :placeholder="$t('OrganizationSuffix')"></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="custom-color-accent-text" text @click="dialog = false">{{$t('cancel')}}</v-btn>
            <v-btn class="custom-color-accent-text" text @click="save">{{$t('save')}}</v-btn>
          </v-card-actions>

        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogReset" max-width="600px">
        <v-card class="customoffwhite">
          <v-card-title class="justify-center ma-0 customlightgray">
            <span class="font-weight-light">{{$t('resetpassword')}}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="ma-0 pa-0">
            <v-container class="ma-0">
              <v-form  v-on:submit.prevent ref="f"
              v-model="v"
              lazy-validation >
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field v-model="resetPasswordItem.password" :rules="[rules.required,rules.min]" 
                                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                                :type="show ? 'text' : 'password'"
                                counter prepend-inner-icon="mdi-lock"
                                autocomplete="off"
                                @click:append="show  = !show"
                                outlined dense :label="$t('password')" :placeholder="$t('password')"></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="custom-color-accent-text" text @click="dialogReset= false">{{$t('cancel')}}</v-btn>
            <v-btn class="custom-color-accent-text" text @click="setPassword">{{$t('save')}}</v-btn>
          </v-card-actions>

        </v-card>
      </v-dialog>
       <v-dialog v-model="dialogSet" max-width="600px">
        <v-card class="customoffwhite">
          <v-card-title class="justify-center ma-0 customlightgray">
            <span class="font-weight-light">{{$t('resetpassword')}}</span>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="ma-0 pa-0">
            <v-container class="ma-0">
              <v-form  v-on:submit.prevent ref="fu"
              v-model="vu"
              lazy-validation > 
                    <v-text-field
                        v-model="user.username"
                        type='text'
                        outlined
                        :label="$t('username')"
                        readonly
                        :rules="[rules.required, rules.minU]"
                        counter
                        prepend-icon="mdi-account"
                        autofocus
                        dense
                        disabled
                        autocomplete="off"
                    ></v-text-field> 
                    <v-text-field
                        v-model="passwordOld"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.required, rules.min]"
                        :type="show1 ? 'text' : 'password'"
                        :placeholder="$t('password')"
                        :label="$t('password')"
                        prepend-icon="mdi-lock"
                        counter
                        dense
                        outlined
                        @click:append="show1 = !show1"
                    ></v-text-field>
                    <v-text-field
                        v-model="passwordNew"
                        :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.required, rules.min]"
                        :type="show2 ? 'text' : 'password'"
                        :placeholder="$t('passwordNew')"
                        :label="$t('passwordNew')"
                        prepend-icon="mdi-lock-outline"
                        counter
                        outlined
                        dense
                        autocomplete="off"
                        @click:append="show2 = !show2"
                    ></v-text-field>
                    <v-text-field
                        v-model="passwordConfirmNew"
                        :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.required, rules.min]"
                        :type="show3 ? 'text' : 'password'"
                        :placeholder="$t('passwordConfirmNew')"
                        :label="$t('passwordConfirmNew')"
                        prepend-icon="mdi-lock-check-outline"
                        counter
                        dense
                        outlined
                        autocomplete="off"
                        @click:append="show3 = !show3"
                    ></v-text-field>
              </v-form>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn class="custom-color-accent-text" text @click="dialogSet= false">{{$t('cancel')}}</v-btn>
            <v-btn class="custom-color-accent-text" text @click="setUserPassword">{{$t('save')}}</v-btn>
          </v-card-actions>

        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title>{{$t('delete')}}</v-card-title>
          <v-card-text>{{$t('Are you sure you want to delete this configuration?')}}</v-card-text>
          <v-card-actions>
            <v-btn class="custom-color-accent-text" text @click="dialogDelete = false">{{$t('Close')}}</v-btn>
            <v-btn class="custom-color-accent-text" text @click="deleteTen()">{{$t('delete')}}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-divider></v-divider>
      <v-data-table :mobile-breakpoint="1081" 
          :headers="headers"
          :items="tenants"
          sort-by="id"
          :items-per-page="15"
          class="elevation-0 customoffwhite text--secondary pa-0 ma-0"
          :search="search.searchWord"
          :loading="loading"
          :loading-text="$t('Loading')"
          :footer-props="{ 'items-per-page-text': $t('rowsperpage') }"
        >
        <template v-slot:no-data> {{$t('noDataAvailable')}}</template>
        <template class="pa-0 ma-0" v-slot:[`item.action`]="{ item }">
          <v-row class="ma-0 pa-0 text-right">
            <v-col cols="12" class="ma-0 pa-0">
              <!-- <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-on='on' v-bind="attrs" icon>
                    <v-icon
                      @click="editItem(item)">
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{$t("edit")}}</span>
              </v-tooltip> -->
               <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-on='on' v-bind="attrs" icon>
                    <v-icon
                      @click="deleteItem(item)">
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{$t("delete")}}</span>
              </v-tooltip>
               <v-tooltip left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-on='on' v-bind="attrs" icon>
                    <v-icon
                      @click="resetPassword(item)">
                      mdi-lock-reset
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{$t("resetpassword")}}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </template>
      </v-data-table>
    </div>
    <!-- alert section -->
    <alertVue
      :key="alertId"
      :alert.sync="alert"
      :alertText.sync="alertText"
      :alertType.sync="alertType"
    ></alertVue>
    <!-- //alert section -->
  </v-card>
</template>

<script>
import {mapActions, mapState} from "vuex";
import alertVue from "@/components/Alert";
export default {
  props:['user'],
  components: {
    alertVue,
  },
  data(){
    return {
      valid: true,
      v: true,
      vu: true,
      show: false,
      show1: false,
      show2: false,
      show3: false,
      showdb: false,
      rules: {
          required: value => !!value || this.$t('required'),
          minU: v => (!v ||v.length >= 3) || this.$t('mincharacters3'), 
          min: v => (!v ||v.length >= 8) || this.$t('mincharacters'),
          email: v => (!v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v)) || this.$t('mailmustbevalid')
      },
      editedIndex: -1,
      headers: [
        { text: this.$t("ID"), value: "id"},
        { text: this.$t("email"), value: "email"},
        { text: this.$t("lastname"), value: "last_name"},
        { text: this.$t("firstname"), value: "first_name"},
        { text: this.$t("telephone"), value: "telephone"},
        { text: this.$t("subdomain"), value: "sub_domain"},
        { text: "", value: "action", sortable: false }
      ],
      dialog: false,
      dialogDelete: false,
      dialogReset: false,
      dialogSet: false,
      alert: false,
      alertText: "",
      alertType: "",
      alertId: "",
      editedItem: {},
      deletedItem: {},
      passwordOld:'',
      passwordNew:'',
      passwordConfirmNew:'',
      resetPasswordItem: {},
      search: {
        searchword:'',
      },
    }
  },
  async mounted(){
    await this.getTenants();
    this.$store.dispatch("retriveUserInfo");
  },
  computed: {
    ...mapState('tenants', {
      tenants: 'tenants',
      loading: 'loading',
      
    }),
    formTitle() {
        return this.editedIndex === -1 ? "newtenant" : "edittenant";
    }
  },
  watch: {
  },
  methods:{
    ...mapActions('tenants',['addTenant', 'updateTenant','deleteTenant','getTenants']),
    save(){
      if(this.editedIndex === -1){
         if(this.$refs.form.validate()){
          this.dialog = false;
          this.addTenant(this.editedItem).then(()=>{
            this.editedItem = {};
            let color="success";
            let alert="itemAddedAlert";
            this.$root.$emit("callAlert", {text: this.$t(alert), type: color, alert: true });
          });
        }
        else
          return
      }else{
        if(this.$refs.form.validate()){
          this.dialog = false;
          this.updateTenant(this.editedItem).then(()=>{
            this.editedItem = {};
            let color="dark";
            let alert="itemUpdatedAlert";
            this.$root.$emit("callAlert", {text: this.$t(alert), type: color, alert: true });
          });
        }
        else
          return
      }
    }, 
    logout() {
      this.$store.dispatch("destroyToken").then(() => {
        this.$router.push({ name: "adminlogin" });
      });
    },
    newItem(){
      this.dialog = true;
      this.editedIndex = -1;
      this.editedItem = {};
    },
    // editItem(item){
    //   this.dialog = true;
    //   this.editedIndex = 0;
    //   Object.assign(this.editedItem,item)
    // },
    deleteItem(item){
      this.editedItem = {};
      Object.assign(this.deletedItem,item)
      this.dialogDelete = true;
    },
    resetPassword(item){
      this.editedItem = {};
      Object.assign(this.resetPasswordItem,item);
      this.resetPasswordItem.password = '';
      this.dialogReset = true;
    },
    setPassword(){
      if(this.$refs.f.validate()){
        this.updateTenant(this.resetPasswordItem).then(()=>{
          this.dialogReset = false;
          this.resetPasswordItem = {};
          let color="dark";
          let alert="itemUpdatedAlert";
          this.$root.$emit("callAlert", {text: this.$t(alert), type: color, alert: true });
        });
      }
    },
    resetUserPassword(){
      this.dialogSet = true;
    },
    callAlert(item) {
      var notifSound = new Audio("notif.mp3");
      notifSound.type = "audio/mp3";
      notifSound.play();
      this.alertText = item.text;
      this.alertType = item.type;
      this.alert = item.alert;
      this.alertId = Date.now();
    },
    setUserPassword(){
       if(this.passwordNew==this.passwordConfirmNew){
          this.user.password=this.passwordNew;
            if(this.$refs.fu.validate()){
              this.$store.dispatch('retriveAdminToken', {
                    username: this.user.username,
                    password: this.passwordOld,
                    }).then(() => {
                    this.updateUser(this.user).then(()=>{
                       this.callAlert({text:this.$t("Password Updated"), type:"success", alert:true});
                      this.$store.dispatch("retriveUserInfo");
                      this.dialogSet=false
                    });

                   }).catch(() => {
                           this.callAlert({text:this.$t("Wrong Old Password"), type:"warning", alert:true});
                   })
            }
           }
           else{
                     this.callAlert({text:this.$t("Something went wrong"), type:"warning", alert:true}); 
           }
    },
    async deleteTen(){
     this.deleteTenant(this.deletedItem.id).then(()=>{
      this.dialogDelete = false;
     })
    },
    ...mapActions(['updateUser']),
  }

}
</script>

<style scoped>
.my-border {border: 1px solid #7a7a7a52 !important}

.resetpwd{
  position:fixed;
  top:10px;
  right:20px;
}
</style>