<template>
  <div class="pa-0 ma-0" v-if="user">
    <tenant-list :user='user'></tenant-list>
  </div>
</template>

<script>
import TenantList from "@/components/admin/AllTenants";
import { mapActions, mapState } from 'vuex';

export default {
  data(){
    return{
      user:null,
    }
  },
  components: {
    'tenant-list': TenantList
  },
  async created(){ 
    await this.getTenants();
   this.user=JSON.parse(localStorage.getItem('user'));

  },
  computed: {
    ...mapState("tenants", {
      tenants: "tenants"
    }),
  },
  methods:{
    ...mapActions('tenants',['getTenants'])
  }
}
</script>

<style scoped>

</style>